<template>
  <div>
    <!-- =========================================================================================
 Search Table & Export
========================================================================================== -->
    <div v-if=" requesturl==='/barcodes/tat'">
      <b-button
        variant="primary"
        class="ml-auto mb-2 mr-2"
        @click="handleExportCSV"
      >
        Export to csv
      </b-button>
      <b-button
        variant="primary"
        class="ml-auto mb-2"
        @click="handleExportExcel"
      >
        Export Excel
      </b-button>
    </div>
    <vue-good-table
      mode="remote"
      :columns="columns"
      :rows="rows"
      :pagination-options="{
        enabled: true,
      }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :total-rows="totalRecords"
      @on-page-change="onPageChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
    >

      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'action'">
          <slot
            name="actions"
            :rowData="props.row"
          />
        </span>

        <span v-else-if="props.column.field === 'reports'">
          <span
            v-if="props.row.reports"
            class="font-weight-bold text-danger"
          >
            <b-badge
              v-for="(report, key) in props.row.reports"
              :key="report"
              pill
              variant="light-primary"
              :href="report"
              target="_blank"
              rel="noopener noreferrer"
            >{{ key }}</b-badge>
          </span>
        </span>

        <span
          v-else-if="props.column.field === 'isObsolete'"
          class="font-weight-bold text-danger"
        >
          <b-badge
            v-if="props.row.isObsolete"
            pill
            variant="light-danger"
          >Obsolete</b-badge>
          <b-badge
            v-else
            pill
            variant="light-success"
          >Not Obsolete</b-badge>
        </span>
        <span v-else-if="props.column.field === 'tags'">
          <span
            v-for="(tag) in props.row.tags"
            :key="tag.name"
          >
            <BarcodeTag
              :color="tag.color"
              :tag-name="tag.name"
              :tag-desc="tag.tooltip"
            />
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="serverParams.perPage"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="serverParams.perPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template></vue-good-table></div></template>
<script>
import { VueGoodTable } from 'vue-good-table'
import { formatDate } from '@/@core/utils/filter'
import BarcodeTag from '@/components/tag.vue'

export default {
  components: {
    VueGoodTable,
    BarcodeTag,
  },
  props: {
    columns: { type: Array, default: () => [] },
    requesturl: { type: String, required: true },
    exportCsvUrl: { type: String, default: () => '' },
    exportExcelUrl: { type: String, default: () => '' },
    serverParamsModifier: {
      type: Function,
      default: serverParams => serverParams,
    },
  },
  data() {
    return {
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        page: 1,
        perPage: 20,
      },
    }
  },
  mounted() {
    this.getTableData()
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.getTableData()
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.getTableData()
    },
    onColumnFilter(params) {
      this.updateParams(params)
      this.getTableData()
    },
    getTableData() {
      this.showLoading()
      const modifiedServerParams = this.serverParamsModifier(this.serverParams)
      this.$http.get(this.requesturl, { params: { queryParams: modifiedServerParams } }).then(response => {
        this.totalRecords = response.data.totalRecords
        this.rows = response.data.list
      }).catch(this.handleError).finally(this.closeLoading)
    },
    handleExportCSV() {
      this.$http({
        method: 'get',
        url: this.exportCsvUrl,
        responseType: 'blob',
        params: {
          queryParams: {
            columnFilters: this.serverParams.columnFilters,
          },
        },
      }).then(resp => {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(resp.data)
        link.setAttribute('download', `${formatDate(Date.now()).replace(/[,\s]+/g, '-')}_responses.csv`) // or any other extension
        document.body.appendChild(link)
        link.click()
        this.$bvToast.toast('Responses Exported successfully', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
      }).catch(this.handleError)
    },
    handleExportExcel() {
      this.$http({
        method: 'get',
        url: this.exportExcelUrl,
        responseType: 'blob',
        params: {
          queryParams: {
            columnFilters: this.serverParams.columnFilters,
          },
        },
      }).then(resp => {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(resp.data)
        link.setAttribute('download', `${formatDate(Date.now()).replace(/[,\s]+/g, '-')}_responses.xlsx`) // or any other extension
        document.body.appendChild(link)
        link.click()
        this.$bvToast.toast('Responses Exported successfully', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
      }).catch(this.handleError)
    },

  },
}
</script>
